import { Button, Form, Tooltip } from "antd";
import { useState } from "react";
import FormInput from "../../../components/form-input/FormInput";
import { useUserInfoStore } from "../../../stores/stores";
import AccountDelete from "./account-delete/AccountDelete";
import "./info-account.scss";
import ModalChangePassword from "./modal-change-passwords/ModalChangePasswords";
import { editAccountInfo } from "../../../service/personal/personalApi";
import { notifySuccess } from "../../../utils/notification";

type InfoAccountProps = {
  dataUser?: any;
};

const InfoAccount = ({ dataUser }: InfoAccountProps) => {
  const [form] = Form.useForm();
  const gmtOffset = "+07:00";
  const location = "Hanoi, Vietnam";
  const timeZone = `GMT ${gmtOffset} ${location}`;
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isChangePassword, setIsChangePassword] = useState<boolean>(false);
  const { infoUser, setUserInfo } = useUserInfoStore();

  const onFinishUpdate = async (values: any) => {
    setIsLoading(true);
    const formData = new FormData();

    if (values.username && values.username !== infoUser.username) {
      formData.append("username", values.username);
    }
    if (values.profileStory)
      formData.append("profileStory", values.profileStory);

    try {
      await editAccountInfo(formData);

      const updatedInfoUser = { ...infoUser };
      for (const key in values) {
        if (values.hasOwnProperty(key)) {
          updatedInfoUser[key] = values[key];
        }
      }

      setUserInfo(updatedInfoUser);
      form.setFieldsValue(updatedInfoUser);
      setIsEdit(false);
      notifySuccess({
        message: "Thông tin cá nhân được cập nhật",
        description: "Thông tin của bạn đã được cập nhật trên hệ thống Mooc",
      });
    } catch (error) {
      console.error("Failed to update profile:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="info-account">
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinishUpdate}
        initialValues={{
          username: infoUser?.username || "",
          email: infoUser?.email || "",
          language: "Tiếng việt",
          timeZone: timeZone,
          bio: "",
          profileStory: infoUser?.profileStory || "",
        }}
      >
        <div className="info-account-title">
          <div>
            <div className="text-title-18">Thông tin tài khoản</div>
            <div className="text-description-14">
              Thay đổi thông tin cài đặt tài khoản của bạn
            </div>
          </div>
          {!isEdit ? (
            <Button
              className="edit-account-btn"
              onClick={() => setIsEdit(true)}
            >
              <img src="/assets/icons/edit-black-icon.svg" alt="" />
              Chỉnh sửa
            </Button>
          ) : (
            <div className="flex gap-12">
              <Button onClick={() => setIsEdit(false)} className="h-40">
                Huỷ
              </Button>
              <Button
                className="save-edit-account-btn"
                type="primary"
                htmlType="submit"
                loading={isLoading}
              >
                <img src="/assets/icons/save-02.svg" alt="" />
                Lưu thông tin
              </Button>
            </div>
          )}
        </div>
        <div className="hr-tag"></div>

        <>
          <div className="basic-information">
            <div className="text-title-14">Thông tin cơ bản</div>
            <div className="text-description-14">
              Các thông tin cá nhân của bạn{" "}
            </div>
          </div>
          <div className="account-info-card">
            <div className="account-info-card___item">
              <div className="item-title">Tên đăng nhập</div>
              <Form.Item name="username">
                <FormInput
                  placeholder="Nhập tên đăng nhập"
                  readOnly={!isEdit}
                />
              </Form.Item>
            </div>
            <div className="account-info-card___item">
              <div className="item-title">Địa chỉ Email</div>
              <Tooltip placement="right" title="Cài đặt không thể thay đổi">
                <span className="tooltip-container">
                  <FormInput
                    prefixIcon={
                      <img
                        src="/assets/icons/mail-01.svg"
                        alt="search"
                        className="search-icon"
                      />
                    }
                    readOnly={true}
                    value={String(infoUser?.email)}
                  />
                </span>
              </Tooltip>
            </div>
            <div className="account-info-card___item">
              <div className="item-title">Ngôn ngữ</div>
              <Tooltip placement="right" title="Cài đặt không thể thay đổi">
                <span className="tooltip-container">
                  <FormInput readOnly={true} value={"Tiếng việt"} />
                </span>
              </Tooltip>
            </div>
            <div className="account-info-card___item">
              <div className="item-title">Múi giờ</div>
              <Tooltip placement="right" title="Cài đặt không thể thay đổi">
                <span className="tooltip-container">
                  <FormInput readOnly={true} value={timeZone} />
                </span>
              </Tooltip>
            </div>
          </div>
        </>
        <>
          <div className="basic-information">
            <div className="text-title-14">Cài đặt bảo mật</div>
            <div className="text-description-14">
              Giữ bí mật thông tin bảo mật của bạn để đảm bảo an toàn cho tài
              khoản
            </div>
          </div>
          <div className="account-info-card">
            <div className="account-info-card___item">
              <Button
                onClick={() => {
                  setIsChangePassword(true);
                }}
                style={{ width: "100%" }}
              >
                <img src="/assets/icons/edit-black-icon.svg" alt="" />
                Thay đổi mật khẩu
              </Button>
            </div>
          </div>
        </>
        <AccountDelete inAccountInfo={true} />
        <ModalChangePassword
          open={isChangePassword}
          hideModal={() => setIsChangePassword(false)}
        />
      </Form>
    </div>
  );
};

export default InfoAccount;
