import {
  EditOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import { Button, ConfigProvider, DatePicker, Form, Input, Radio } from "antd";
import { useForm } from "antd/es/form/Form";
import locale from "antd/es/locale/vi_VN";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import { get, isArray, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FormInput from "../../../components/form-input/FormInput";
import {
  editAccountInfo,
  getCountry,
  getDistricts,
  getProvinces,
  getWards,
} from "../../../service/personal/personalApi";
import { useUserInfoStore } from "../../../stores/stores";
import { notifySuccess } from "../../../utils/notification";
import PaginatedSelect from "../../PaginatedSelect/PaginatedSelect";
import AvatarUploader from "./components/AvatarUploader/AvatarUploader";
import "./profile-document.scss";

type ProfileDocumentProps = {
  universityList: any;
  educationLevelList: any;
  industryList: any;
  hobbyList: any;
};
dayjs.locale("vi");

const validate = {
  phone: {
    validator: (_: any, value: any) => {
      if (value?.length > 11 || value?.length < 9) {
        return Promise.reject(
          new Error("Số điện thoại không đúng, vui lòng nhâp lại")
        );
      }
      return Promise.resolve();
    },
  },
};

type DataOptions = {
  dataProvinces: any[];
  dataDistricts: any[];
  dataWards: any[];
  dataCountry: any[];
};

const ProfileDocument = (props: ProfileDocumentProps) => {
  const { universityList, educationLevelList, industryList, hobbyList } = props;
  const [form] = useForm();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [imgUrl, setImgUrl] = useState<string>();
  const { infoUser, setUserInfo } = useUserInfoStore();
  const [visible, setVisible] = useState(false);
  const [isViewPhoneNumber, setIsViewPhoneNumber] = useState<boolean>(false);
  const [isViewAddress, setIsViewAddress] = useState<boolean>(false);

  const [dataOptions, setDataOptions] = useState<DataOptions>({
    dataProvinces: [],
    dataDistricts: [],
    dataWards: [],
    dataCountry: [],
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchCountry = async () => {
    try {
      const res = await getCountry();
      const country = res.data.data?.map((data: any) => ({
        ...data,
        value: data.code,
        label: data.name,
      }));
      setDataOptions((prevOptions) => ({
        ...prevOptions,
        dataCountry: country,
      }));
    } catch (error) {
      console.error("Failed to fetch countries:", error);
    }
  };

  useEffect(() => {
    fetchCountry();
  }, []);

  const fetchProvinces = async (countryId: string) => {
    try {
      const res = await getProvinces(countryId);
      const provinces = res.data.data?.map((data: any) => ({
        ...data,
        value: data.code,
        label: data.name,
      }));
      setDataOptions((prevOptions) => ({
        ...prevOptions,
        dataProvinces: provinces,
      }));
    } catch (error) {
      console.error("Failed to fetch provinces:", error);
    }
  };

  const fetchDistrict = async (provinceId: string) => {
    if (!isEmpty(form.getFieldValue("provinceId"))) {
      try {
        const res = await getDistricts(provinceId);
        setDataOptions((prevOptions) => ({
          ...prevOptions,
          dataDistricts: res.data.data?.map((data: any) => ({
            ...data,
            value: data.code,
            label: data.name,
          })),
        }));
      } catch (error) {
        console.error("Failed to fetch districts:", error);
      }
    }
  };

  const fetchWard = async (districtId: string) => {
    if (!isEmpty(form.getFieldValue("districtId"))) {
      try {
        const res = await getWards(districtId);
        setDataOptions((prevOptions) => ({
          ...prevOptions,
          dataWards: res.data.data?.map((data: any) => ({
            ...data,
            value: data.code,
            label: data.name,
          })),
        }));
      } catch (error) {
        console.error("Failed to fetch wards:", error);
      }
    }
  };

  useEffect(() => {
    fetchProvinces(form.getFieldValue("countryId"));
  }, [form.getFieldValue("countryId")]);

  useEffect(() => {
    fetchDistrict(form.getFieldValue("provinceId"));
  }, [form.getFieldValue("provinceId")]);

  useEffect(() => {
    fetchWard(form.getFieldValue("districtId"));
  }, [form.getFieldValue("districtId")]);

  useEffect(() => {
    if (infoUser) {
      setImgUrl(infoUser.image);
      form.setFieldsValue({
        fullName: infoUser.fullName,
        dateOfBirth: infoUser.dateOfBirth ? dayjs(infoUser.dateOfBirth) : null,
        gender: infoUser.gender,
        school: infoUser.school,
        academicLevel: infoUser.academicLevel?.map((it: any) => it.id),
        specialized: infoUser.industries?.map((it: any) => it.id),
        studentFavorites: infoUser?.studentFavorites?.map((it: any) => it?.id),
        phoneNumber: infoUser.phoneNumber,
        address: infoUser.address,
        provinceId: infoUser.provinceId,
        districtId: infoUser.districtId,
        wardId: infoUser.wardId,
        countryId: Number(infoUser.countryId) || undefined,
      });
    }
  }, [infoUser]);

  useEffect(() => {
    if (infoUser) {
      setImgUrl(infoUser.image);
    }
  }, [infoUser]);
  const handleChangeCountry = (value: string) => {
    fetchProvinces(value);
    form.setFieldsValue({
      countryId: value,
      provinceId: undefined,
      districtId: undefined,
      wardId: undefined,
    });
  };

  const handleChangeProvince = (value: string) => {
    fetchDistrict(value);
    form.setFieldsValue({
      provinceId: value,
      districtId: undefined,
      wardId: undefined,
    });
  };

  const handleChangeDistrict = (value: string) => {
    fetchWard(value);
    form.setFieldsValue({
      districtId: value,
      wardId: undefined,
    });
  };

  const cancelEdit = () => {
    setIsEdit(false);
    if (infoUser) {
      form.setFieldsValue({
        fullName: infoUser.fullName,
        dateOfBirth: infoUser.dateOfBirth ? dayjs(infoUser.dateOfBirth) : null,
        gender: infoUser.gender,
        school: infoUser.school,
        academicLevel: infoUser.academicLevel?.map((it: any) => it.id),
        specialized: infoUser.industries?.map((it: any) => it.id),
        studentFavorites: infoUser?.studentFavorites?.map((it: any) => it.id),
        phoneNumber: infoUser.phoneNumber,
        address: infoUser.address,
        provinceId: infoUser.provinceId,
        districtId: infoUser.districtId,
        wardId: infoUser.wardId,
        countryId: Number(infoUser.countryId),
      });
    }
  };

  const onFinishUpdate = async (values: any) => {
    setIsLoading(true);

    const formData = new FormData();
    if (values.fullName && values.fullName !== infoUser.fullName)
      formData.append("name", values.fullName);
    if (
      values.dateOfBirth &&
      dayjs(values.dateOfBirth).format("DD/MM/YYYY") !==
        dayjs(infoUser.dateOfBirth).format("DD/MM/YYYY")
    ) {
      formData.append("dateOfBirth", values.dateOfBirth.toISOString());
    }
    if (values.gender && values.gender !== infoUser.gender)
      formData.append("gender", values.gender);
    if (values.school && values.school !== infoUser.school)
      formData.append("universityId", values.school);
    if (
      values.specialized?.length > 0 &&
      values.specialized !== infoUser.industries?.map((it: any) => it.id)
    )
      formData.append("industryIds", values.specialized);
    if (
      values.academicLevel !== infoUser.academicLevel?.map((it: any) => it.id)
    )
      formData.append("academicLevelIds", values.academicLevel);
    if (
      values.studentFavorites?.length > 0 &&
      values.studentFavorites !==
        infoUser.studentFavorites?.map((it: any) => it.id)
    )
      formData.append("favouriteTagIds", values.studentFavorites);
    if (values.phoneNumber && values.phoneNumber !== infoUser.phoneNumber)
      formData.append("phoneNumber", values.phoneNumber);
    if (values.address && values.address !== infoUser.address)
      formData.append("address", values.address);
    if (values.provinceId && values.provinceId !== infoUser.provinceId)
      formData.append("provinceCode", values.provinceId);
    if (values.districtId && values.districtId !== infoUser.districtId)
      formData.append("districtCode", values.districtId);
    if (values.wardId && values.wardId !== infoUser.wardId)
      formData.append("wardCode", values.wardId);
    if (values.countryId && values.countryId !== infoUser.country)
      formData.append("countryCode", values.countryId);
    if (values.phoneNumber) formData.append("phoneNumber", values.phoneNumber);
    try {
      await editAccountInfo(formData);
      const updatedInfoUser = {
        ...infoUser,
        ...values,
        academicLevel: educationLevelList
          .filter((item: any) =>
            isArray(values.academicLevel)
              ? values.academicLevel?.includes(item.value)
              : item.value === values.academicLevel
          )
          .map((item: any) => ({ id: item.value, levelName: item.label })),
        industries: industryList
          .filter((item: any) => values.specialized?.includes(item.value))
          .map((item: any) => ({ id: item.value, name: item.label })),
        studentFavorites: hobbyList
          .filter((item: any) => values.studentFavorites?.includes(item.value))
          .map((item: any) => ({ id: item.value, name: item.label })),
        // country: get(dataOptions, "dataCountry", []).find(
        //   (item: any) => item.value === values.countryId
        // ),
      };

      setUserInfo(updatedInfoUser);
      form.setFieldsValue(updatedInfoUser);

      setIsEdit(false);
      notifySuccess({
        message: "Thông tin cá nhân được cập nhật",
        description: "Thông tin của bạn đã được cập nhật trên hệ thống Mooc",
      });
    } catch (error) {
      console.error("Failed to update profile:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const getAddress = () => {
    const provinceId = form.getFieldValue("provinceId") || infoUser.provinceId;
    const districtId = form.getFieldValue("districtId") || infoUser.districtId;
    const wardId = form.getFieldValue("wardId") || infoUser.wardId;
    const provinceName = get(dataOptions, "dataProvinces", []).find(
      (it) => it?.code === provinceId
    )?.fullName;

    const districtName = get(dataOptions, "dataDistricts", []).find(
      (it) => it?.code === districtId
    )?.fullName;

    const wardName = get(dataOptions, "dataWards", []).find(
      (it) => it?.code === wardId
    )?.fullName;

    const addressParts = [
      infoUser.address,
      provinceName,
      districtName,
      wardName,
    ];

    return addressParts.filter(Boolean).join(", ").trim();
  };
  return (
    <div className="profile-document">
      <Form
        layout="vertical"
        form={form}
        className="form-edit--info"
        onFinish={onFinishUpdate}
      >
        <div className="flex align-center justify-between">
          <div>
            <div className="text-title-18">Hồ sơ cá nhân</div>
            <div className="text-description-14">
              Thay đổi thông tin cá nhân của bạn{" "}
            </div>
          </div>
          {!isEdit ? (
            <Button
              className="edit-profile-btn"
              onClick={() => setIsEdit(true)}
            >
              <img src="/assets/icons/edit-black-icon.svg" alt="" />
              Chỉnh sửa
            </Button>
          ) : (
            <div className="flex gap-12">
              <Button onClick={cancelEdit}>Huỷ</Button>
              <Button
                className="save-edit-profile-btn"
                type="primary"
                htmlType="submit"
                loading={isLoading}
              >
                <img src="/assets/icons/save-02.svg" alt="" />
                Lưu thông tin
              </Button>
            </div>
          )}
        </div>
        <div className="hr-tag"></div>

        <div className="mt-4 mb-3 flex align-center justify-between">
          <div>
            <div className="text-title-14">Thông tin chung</div>
            <div className="text-description-14">
              Các thông tin cá nhân của bạn
            </div>
          </div>

          <Link to={"/identify-ekyc"}>
            <Button className="account-verification-btn">
              <img src="/assets/icons/shield-tick.svg" alt="" />
              Xác thực thông tin tài khoản
            </Button>
          </Link>
        </div>

        <div className="profile-card">
          <div className="profile-info">
            <div className="profile-image">
              <AvatarUploader imgUrl={imgUrl} />
            </div>
            <div className="profile-card-item"></div>
            <Form.Item label="Họ và tên" name="fullName">
              {isEdit ? (
                <FormInput suffix={<EditOutlined />} readOnly={!isEdit} />
              ) : (
                <Form.Item shouldUpdate noStyle>
                  {({ getFieldValue }) => (
                    <Input
                      value={getFieldValue("fullName")}
                      readOnly
                      className="default-height-input"
                    />
                  )}
                </Form.Item>
              )}
            </Form.Item>
          </div>
          <div className="profile-info--horizontal profile-card-item">
            <div className="profile-info--dob">
              <ConfigProvider locale={locale}>
                {isEdit ? (
                  <Form.Item label="Ngày sinh" name="dateOfBirth">
                    <DatePicker
                      placeholder="Chọn ngày sinh"
                      format="DD/MM/YYYY"
                      style={{
                        marginRight: 8,
                        width: "100%",
                        height: "44px",
                      }}
                      disabledDate={(current) =>
                        current && current > dayjs().endOf("day")
                      }
                      popupClassName="dateofbirth-picker"
                    />
                  </Form.Item>
                ) : (
                  <>
                    <div className="ant-col ant-form-item-label mb-1">
                      <label htmlFor="">Ngày sinh</label>
                    </div>
                    <Input
                      value={
                        infoUser.dateOfBirth && visible
                          ? dayjs(infoUser.dateOfBirth).format("DD/MM/YYYY")
                          : infoUser.dateOfBirth && !visible
                            ? "**/**/****"
                            : undefined
                      }
                      readOnly={!isEdit}
                      style={{ height: 44 }}
                      autoComplete="off"
                      prefix={
                        <img
                          src="/assets/icons/calendar.svg"
                          alt="search"
                          className="search-icon"
                        />
                      }
                      suffix={
                        <div
                          onClick={() => setVisible(!visible)}
                          className="pointer"
                        >
                          {visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />}
                        </div>
                      }
                    />
                  </>
                )}
              </ConfigProvider>
            </div>
            <div className="profile-info--gender">
              <Form.Item
                label={
                  <div className="label-gender">
                    <span>Giới tính</span>
                  </div>
                }
                name="gender"
              >
                <Radio.Group options={dataGender} optionType={"default"} />
              </Form.Item>
            </div>
          </div>
          <div className="profile-card-item">
            <Form.Item label="Trường học" name="school">
              <PaginatedSelect
                options={universityList}
                pageSize={100}
                debounceTimeout={800}
                placeholder="Chọn trường học"
                style={{ width: "100%", minHeight: 44 }}
                allowClear={true}
                disabled={!isEdit}
              />
            </Form.Item>
          </div>

          <div className="profile-card-item">
            <Form.Item label="Trình độ học vấn" name="academicLevel">
              <PaginatedSelect
                options={educationLevelList}
                pageSize={100}
                debounceTimeout={800}
                placeholder="Chọn trình độ học vấn"
                style={{ width: "100%", minHeight: 44 }}
                disabled={!isEdit}
              />
            </Form.Item>
          </div>

          <div className="profile-card-item">
            <Form.Item label="Chuyên ngành" name="specialized">
              <PaginatedSelect
                options={industryList}
                pageSize={100}
                debounceTimeout={800}
                placeholder="Chọn chuyên ngành"
                style={{ width: "100%", minHeight: 44 }}
                disabled={!isEdit}
                mode="multiple"
              />
            </Form.Item>
          </div>

          <div className="profile-card-item">
            <Form.Item label="Sở thích" name="studentFavorites">
              <PaginatedSelect
                options={hobbyList}
                pageSize={100}
                debounceTimeout={800}
                placeholder="Chọn sở thích"
                style={{ width: "100%", minHeight: 44 }}
                mode="multiple"
                disabled={!isEdit}
              />
            </Form.Item>
          </div>
        </div>
        <div className="mt-4">
          <div className="text-title-14">Thông tin liên hệ</div>
          <div className="text-description-14">
            Thông tin liên hệ của bạn được ghi nhận trên hệ thống Mooc{" "}
          </div>
        </div>
        <div className="profile-card mt-3">
          <div className="ant-col ant-form-item-label mb-1">
            <label htmlFor="">Số điện thoại</label>
          </div>

          {isEdit ? (
            <Form.Item
              name="phoneNumber"
              rules={[
                {
                  pattern: /^[0-9]{9,11}$/,
                  message: "Số điện thoại không đúng, vui lòng nhập lại",
                },
              ]}
            >
              <FormInput suffix={<EditOutlined />} />
            </Form.Item>
          ) : (
            <Input
              value={
                isViewPhoneNumber
                  ? form.getFieldValue("phoneNumber")
                  : "**********"
              }
              readOnly
              style={{ height: "44px" }}
              suffix={
                <div
                  onClick={() => setIsViewPhoneNumber(!isViewPhoneNumber)}
                  className="pointer"
                >
                  {isViewPhoneNumber ? (
                    <EyeTwoTone />
                  ) : (
                    <EyeInvisibleOutlined />
                  )}
                </div>
              }
            />
          )}
          <div className="profile-card-item address">
            <div className="ant-col ant-form-item-label mb-1">
              <label htmlFor="">Địa chỉ của bạn</label>
            </div>
            <div>
              {isEdit ? (
                <div className="form-address">
                  <Form.Item name="countryId">
                    <PaginatedSelect
                      onChange={handleChangeCountry}
                      options={get(dataOptions, "dataCountry")}
                      placeholder="Chọn Quốc gia"
                    />
                  </Form.Item>

                  {form.getFieldValue("countryId") === 4 ? (
                    <>
                      <Form.Item name="provinceId">
                        <PaginatedSelect
                          onChange={handleChangeProvince}
                          options={get(dataOptions, "dataProvinces")}
                          placeholder="Chọn thành phố"
                        />
                      </Form.Item>
                      <Form.Item name="districtId">
                        <PaginatedSelect
                          onChange={handleChangeDistrict}
                          options={get(dataOptions, "dataDistricts")}
                          disabled={!form.getFieldValue("provinceId")}
                          placeholder="Chọn Quận/Huyện"
                        />
                      </Form.Item>
                      <Form.Item name="wardId">
                        <PaginatedSelect
                          options={get(dataOptions, "dataWards")}
                          disabled={
                            (form.getFieldValue("provinceId") &&
                              !form.getFieldValue("districtId")) ||
                            (!form.getFieldValue("provinceId") &&
                              form.getFieldValue("districtId"))
                          }
                          placeholder="Chọn Phường/Xã"
                        />
                      </Form.Item>
                    </>
                  ) : null}
                </div>
              ) : (
                <Input
                  value={isViewAddress ? getAddress() : "Thông tin bảo mật"}
                  suffix={
                    <div
                      onClick={() => setIsViewAddress(!isViewAddress)}
                      className="pointer"
                    >
                      {isViewAddress ? (
                        <EyeTwoTone />
                      ) : (
                        <EyeInvisibleOutlined />
                      )}
                    </div>
                  }
                  readOnly={!isEdit}
                  className="default-height-input"
                />
              )}
              {isEdit && (
                <div className="profile-card-item">
                  <Form.Item name="address">
                    <FormInput suffix={<EditOutlined />} readOnly={!isEdit} />
                  </Form.Item>
                </div>
              )}
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default ProfileDocument;

const dataGender = [
  {
    label: "Nam",
    value: "male",
  },
  {
    label: "Nữ",
    value: "female",
  },
  {
    label: "Khác",
    value: "other",
  },
];
