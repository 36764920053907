import "./style.scss";

import { Button, ConfigProvider, Form, Select } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import MoocBreadcrumb from "../../components/breadcrumb";
import {
  CLASS_SCHEDULE,
  EXAM,
  EXERCISE,
  EXTRA_CLASS_SCHEDULE,
  TEST,
} from "../../constants";
import {
  getCalendarList,
  getConfigCalendar,
  getMyCourseListCalendar,
} from "../../service/calendar/calendar";
import { globalStore, studyScheduleStore } from "../../stores/stores";
import { StudyCalendarProps } from "../../types/calendar";
import { filterOption } from "../../utils/element";
import { getStartAndEndOfYear } from "../../utils/format";
import { objectToQueryString } from "../../utils/getPageFromLink";
import { viLocale } from "../../utils/picker";
import CustomRangePickerCalendar from "./components/custom-range-picker-calendar/CustomRangePickerCalendar";
import SettingScheduleCalendar from "./components/setting-schedule-calendar/SettingScheduleCalendar";
import StudyScheduleCalendar from "./components/study-schedule-calendar/StudyScheduleCalendar";
import StudyScheduleMenu from "./components/study-schedule-menu/StudyScheduleMenu";
import { adjustAllDayEventTimes } from "./components/study-schedule-calendar/event-content";

type Props = {};

const breadcrumbItem = [
  {
    href: "",
    title: <img src={`${process.env.PUBLIC_URL}/assets/icons/home-line.svg`} />,
  },
  {
    href: "",
    title: (
      <>
        <span>Lịch học</span>
      </>
    ),
  },
];

const StudySchedule = (props: Props) => {
  const [form] = Form.useForm();
  const { setCalendarSettings, setDateRange, setCurrentView, setInitialDate } =
    studyScheduleStore();
  const { reload } = globalStore();
  const [courses, setCourses] = useState([]);
  const [moveSetting, setMoveSetting] = useState<boolean>(false);
  const [calendarList, setCalendarList] = useState<StudyCalendarProps[]>([]);
  const [scheduleTypes, setScheduleTypes] = useState<number[]>([0, 1, 2, 3, 4]);
  const [dateChange, setDateChange] = useState<any>();
  const [configData, setConfigData] = useState<any>([]);
  const [currentYear, setCurrentYear] = useState<number>(
    new Date().getFullYear()
  );

  useEffect(() => {
    const fetchIndustryInMyCourse = async () => {
      const params = {
        size: 1000000,
      };
      try {
        const res = await getMyCourseListCalendar(objectToQueryString(params));

        const coursesData = res.data?.data?.map((item: any) => ({
          label: item.name,
          value: item.id,
        }));
        setCourses(coursesData);
      } catch (err) {
        console.error(err);
      }
    };
    fetchIndustryInMyCourse();
  }, []);

  const handleMoveSetting = () => {
    setMoveSetting(true);
  };

  useEffect(() => {
    const date = dateChange ? new Date(dateChange) : new Date(Date.now());
    date.setMonth(date.getMonth() + 1);
    const year = date.getFullYear();

    if (year !== currentYear) {
      setCurrentYear(year);
      const { startOfYear, endOfYear } = getStartAndEndOfYear(date);
      fetchCalendarList(startOfYear, endOfYear, form.getFieldsValue());
    }
  }, [currentYear]);

  useEffect(() => {
    const fetchConfigCalendar = async () => {
      const res = await getConfigCalendar();
      const configData = res?.data?.data;
      setConfigData(configData);
      setCalendarSettings(configData);
    };
    fetchConfigCalendar();
  }, [reload]);

  const fetchCalendarList = async (
    startOfYear: Date,
    endOfYear: Date,
    formValues: any
  ) => {
    const payloads = {
      dateFrom: formValues?.registeredDate?.[0]
        ? dayjs(formValues?.registeredDate[0]).endOf("day").toISOString()
        : startOfYear,
      dateTo: formValues?.registeredDate?.[1]
        ? dayjs(formValues?.registeredDate[1]).endOf("day").toISOString()
        : endOfYear,
      scheduleTypes: scheduleTypes,
      keyword: formValues?.keyword?.trim() || undefined,
      courseId: formValues.courseId || undefined,
    };
    try {
      const res = await getCalendarList(payloads);

      const data = res?.data.data;
      setCalendarList(
        data.map((it: StudyCalendarProps) => ({
          ...it,
          sequenceId: it.sequenceId,
          sectionId: it.sectionId,
          unitId: it.id,
          classNames: [
            it.type === CLASS_SCHEDULE
              ? "class-schedule"
              : it.type === EXERCISE
                ? "exercise"
                : it.type === TEST
                  ? "test"
                  : it.type === EXAM
                    ? "exam"
                    : it.type === EXTRA_CLASS_SCHEDULE
                      ? "extra-class-schedule"
                      : null,
          ],
        }))
      );
    } catch (error) {}
  };

  useEffect(() => {
    const date = dateChange ? new Date(dateChange) : new Date(Date.now());
    date.setMonth(date.getMonth() + 1);
    const { startOfYear, endOfYear } = getStartAndEndOfYear(date);
    fetchCalendarList(startOfYear, endOfYear, form.getFieldsValue());
  }, [scheduleTypes]);

  return (
    <div className="study-schedule">
      <MoocBreadcrumb items={breadcrumbItem} />
      <div className="mt-3 flex justify-between">
        <div className="text-title-30">Lịch học của tôi</div>
      </div>

      <div className="study-schedule-content">
        <Form
          form={form}
          onValuesChange={() => {
            const date = dateChange
              ? new Date(dateChange)
              : new Date(Date.now());
            date.setMonth(date.getMonth() + 1);
            const { startOfYear, endOfYear } = getStartAndEndOfYear(date);
            fetchCalendarList(startOfYear, endOfYear, form.getFieldsValue());
          }}
        >
          <div className="flex justify-between align-center gap-16">
            <div>
              <div className="text-title-18">Lịch học</div>
              <div className="my-notes-des">Tổng hợp lịch học của khoá học</div>
            </div>
            <div className="study-schedule-search">
              <Form.Item name="courseId">
                <Select
                  showSearch
                  className="btn-filter"
                  style={{ width: "100%", height: 44, minWidth: "186px" }}
                  options={courses}
                  filterOption={filterOption}
                  allowClear
                  placeholder="Tất cả khoá học"
                  notFoundContent={<span>Không có kết quả</span>}
                />
              </Form.Item>
              <Form.Item name="registeredDate">
                <ConfigProvider locale={viLocale}>
                  <CustomRangePickerCalendar
                    isDelete={true}
                    value={form.getFieldValue("registeredDate") || null}
                    onChangeRangePicker={(dates) => {
                      form.setFieldsValue({ registeredDate: dates });
                      // setDateRange(dates as any);
                      setCurrentView(String(Date.now()));
                      setInitialDate(dayjs(dates?.[0]).toISOString());
                      const date = dates
                        ? new Date(dateChange)
                        : new Date(Date.now());
                      date.setMonth(date.getMonth() + 1);
                      const { startOfYear, endOfYear } =
                        getStartAndEndOfYear(date);
                      fetchCalendarList(
                        startOfYear,
                        endOfYear,
                        form.getFieldsValue()
                      );
                    }}
                    onDelete={() => {
                      const date = dateChange
                        ? new Date(dateChange)
                        : new Date(Date.now());
                      date.setMonth(date.getMonth() + 1);
                      const { startOfYear, endOfYear } =
                        getStartAndEndOfYear(date);
                      form.setFieldsValue({ registeredDate: null });
                      fetchCalendarList(
                        startOfYear,
                        endOfYear,
                        form.getFieldsValue()
                      );
                    }}
                    placeholder="Chọn thời gian"
                    height={"44px"}
                  />
                </ConfigProvider>
              </Form.Item>
              <Button onClick={handleMoveSetting}>
                <img
                  src="/assets/icons/settings-02.svg"
                  alt="search"
                  className="search-icon"
                />
              </Button>
            </div>
          </div>
        </Form>
        <div className="hr-tag"></div>

        {moveSetting ? (
          <SettingScheduleCalendar
            onBack={() => setMoveSetting(false)}
            configData={configData}
          />
        ) : (
          <div className="study-schedule-calendar-view">
            <StudyScheduleMenu
              setScheduleTypes={setScheduleTypes}
              dateChange={dateChange}
              setDateChange={setDateChange}
            />

            <StudyScheduleCalendar
              calendarList={adjustAllDayEventTimes(calendarList)}
              setDateChange={setDateChange}
              configData={configData}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default StudySchedule;
