import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  DownOutlined,
  UnorderedListOutlined,
  UpOutlined,
} from "@ant-design/icons";
import {
  AutoComplete,
  Button,
  Checkbox,
  Empty,
  Input,
  Select,
  Spin,
  Tabs,
} from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { debounce, toNumber } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSubscription } from "react-stomp-hooks";
import MoocMenu from "../../../components/menu/MoocMenu";
import ConfirmModal from "../../../components/modal/confirm-modal/ConfirmModal";
import LearningReviewModal from "../../../components/modal/learning-review-modal/LearningReviewModal";
import { UNIT_DOWNLOAD, UNIT_SHARE } from "../../../constants";
import useKeyword from "../../../hooks/useKeyword";
import { usePreventRouteChange } from "../../../hooks/usePreventRouteChange";
import { submitAttendanceReport } from "../../../service/attendance";
import {
  checkUnitComplete,
  countLearningCourse,
  downloadQuiz,
  getUnitDetail,
  reviewSequence,
  reviewUnit,
  searchCourseStructure,
} from "../../../service/learning-course";
import { globalStore, learningCourseStore } from "../../../stores/stores";
import { Block, Sections } from "../../../types/course";
import { downloadWordDocument } from "../../../utils/HtmlToDocxConverter";
import { filterOption, scrollToElement } from "../../../utils/element";
import { notifyError, notifySuccess } from "../../../utils/notification";
import FaceRecordModal from "../components/modals/FaceRecordModal";
import Discussion from "../discussion/Discussion";
import "./LearningCourse.scss";
import PDFViewer from "./components/PDF-viewer/PDFViewer";
import SCORMViewer from "./components/SCORM-viewer/SCORMViewer";
import XAPIViewer from "./components/XAPI-viewer/XAPIViewer";
import MultimediaPlayer from "./components/multimedia-player/MultimediaPlayer";
import QuizScreen from "./components/quiz-screen";
import TextViewer from "./components/text-viewer/TextViewer";
import UnitNotes from "./components/unit-notes/UnitNotes";
import { converDataLearningCourse } from "./converDataLearningCourse";

const { TabPane } = Tabs;

type Props = {
  tab: string;
};

interface TotalAction {
  totalView: number;
  totalShare: number;
  totalDownload: number;
}

const LearningCourse = ({ tab }: Props) => {
  const {
    learningCourseData,
    setUnitData,
    unitData,
    setUnitId,
    notes,
    setSectionId,
    setSequenceId,
    sectionId,
    sequenceId,
    unitId,
  } = learningCourseStore();
  const { confirmRedirect } = usePreventRouteChange();
  const { reload } = globalStore();
  const navigate = useNavigate();
  const blockId = toNumber(useKeyword("blockId"));

  const { id } = useParams();
  const { search } = useLocation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isToggle, setIsToggle] = useState<boolean>(true);
  const [isContentVisible, setIsContentVisible] = useState<boolean>(true);
  const [unitList, setUnitList] = useState<number[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [sections, setSections] = useState<any>([]);
  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false);
  const [tempChecked, setTempChecked] = useState<boolean>(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const [unitTypeTab, setUnitTypeTab] = useState<string>("1");
  const [searchType, setSearchType] = useState<string>("sequence");
  const [keyword, setKeyword] = useState<string>("");
  const [courseStructList, setCourseStructList] = useState<any[]>();
  const [isUnitReview, setIsUnitReview] = useState<boolean>(false);
  const [isSequenceReview, setIsSequenceReview] = useState<boolean>(false);
  const [nextUnitId, setNextUnitId] = useState<number>(0);
  const [lastUnit, setLastUnit] = useState<boolean>(false);
  const [isExpand, setIsExpand] = useState(true);
  const [isCloseCamera, setIsCloseCamera] = useState<any>("");
  const [attendanceData, setAttendanceData] = useState<any>({
    course_block_id: 0,
    report_id: 0,
    teacher_id: 0,
  });

  const handleAttendance = async (
    blockId: number,
    report_id: number,
    teacher_id: number
  ) => {
    try {
      submitAttendanceReport({
        course_block_id: `${blockId}`,
        teacher_id: teacher_id,
        report_id: report_id,
        is_attendance: search.includes(`blockId=${blockId}`),
      });
    } catch (error) {}
  };

  useEffect(() => {
    setTimeout(() => {
      setSections(converDataLearningCourse(learningCourseData.sections));
    }, 200);
  }, [reload, learningCourseData]);

  useSubscription(`/topic/block.${blockId}`, (message: any) => {
    // When a message is received, add it to the messages state
    let payload = JSON.parse(message.body);

    if (payload?.is_face === 1) {
      if (search.includes(`blockId=${payload?.course_block_id}`)) {
        setIsCloseCamera("open");
      }
      setAttendanceData({
        course_block_id: payload?.course_block_id,
        report_id: payload?.report_id,
        teacher_id: payload?.teacher_id,
      });
    } else {
      if (search.includes(`blockId=${payload?.course_block_id}`)) {
        handleAttendance(
          payload?.course_block_id,
          payload?.report_id,
          payload?.teacher_id
        );
      }
    }
  });
  const [starRating, setStarRating] = useState<number>(5);
  const [isLoadReview, setIsLoadReview] = useState<boolean>(false);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [reloadTotal, setReloadTotal] = useState<boolean>(false);
  const [totalAction, setTotalAction] = useState<TotalAction>({
    totalView: 0,
    totalShare: 0,
    totalDownload: 0,
  });

  const markerList = notes.map((note) => {
    return {
      time: note?.videoPositionStart,
      label: note?.title,
      id: note?.id,
      pageIndex: note?.documentPosition,
    };
  });

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const renderMaterial = (material: Block) => {
    switch (material?.module) {
      case "video":
        return (
          <MultimediaPlayer
            url={material?.documents[0]?.mainKeyUrl}
            markers={markerList}
            checkingAttendant={true}
          />
        );
      case "mp3":
        return (
          <MultimediaPlayer
            url={material?.documents[0]?.mainKeyUrl}
            markers={markerList}
            isAudio={true}
          />
        );
      case "pdf":
        return (
          <PDFViewer
            url={material?.documents[0]?.mainKeyUrl}
            markers={markerList}
          />
        );
      case "text":
        return <TextViewer content={material?.documents[0]?.text} />;
      case "scorm":
        return <SCORMViewer url={material?.scorms[0]?.mainKeyUrl} />;
      case "xapi":
        return <XAPIViewer url={material?.scorms[0]?.mainKeyUrl} />;
      case "quiz":
        return <QuizScreen onChangeMenuMode={setIsToggle} />;
      case "reference":
        return (
          <a
            onClick={() =>
              openInNewTab(
                material?.documents[0]?.mainKeyUrl ||
                  material?.documents[0]?.mainKey
              )
            }
            className="link-to-reference"
          >
            {unitData?.name}
          </a>
        );
      default:
        return <Empty description="Không có kết quả" />;
    }
  };
  const toggleContentVisibility = () => {
    setIsContentVisible(!isContentVisible);
  };

  const navigateToUnit = (unitId: number) => {
    const unit = findUnitById(unitId);
    setUnitId(unitId);
    navigate(
      `${location.pathname}?blockId=${unit.block[0].id}&unitId=${unitId}`
    );
  };
  const handlePrevNext = (direction: "prev" | "next") => {
    const findCurrentAndSiblingUnits = (unitId: number) => {
      for (const section of sections) {
        for (const child of section.children) {
          const unitIndex = child.children.findIndex(
            (unit: any) => unit.id === unitId
          );
          if (unitIndex !== -1) {
            return {
              currentIndex: unitIndex,
              units: child.children,
              child,
              section,
            };
          }
        }
      }
      return null;
    };

    const result = findCurrentAndSiblingUnits(unitId as any);
    if (!result) return;

    const { currentIndex, units, child, section } = result;
    const targetIndex = currentIndex + (direction === "next" ? 1 : -1);

    const handleNext = () => {
      if (targetIndex < units.length) {
        const nextUnitId = units[targetIndex].id;
        if (isAccessible(nextUnitId)) {
          setLastUnit(false);
          setNextUnitId(nextUnitId);
          if (!unitData?.isUnitReviewed && unitData?.isCompleted) {
            setIsUnitReview(true);
          } else {
            navigateToUnit(nextUnitId);
          }
        }
        return;
      }
      const childIndex = section.children.findIndex(
        (c: any) => c.id === child.id
      );
      if (childIndex !== -1 && childIndex + 1 < section.children.length) {
        const nextChildUnitId = section.children[childIndex + 1].children[0].id;
        setLastUnit(true);
        setNextUnitId(nextChildUnitId);
        if (!unitData?.isCompleted) {
          navigateToUnit(nextChildUnitId);
        } else if (!unitData?.isUnitReviewed && unitData?.isCompleted) {
          setIsUnitReview(true);
        } else if (
          unitData?.isUnitReviewed &&
          unitData?.isCompleted &&
          !unitData?.isSequenceReviewed
        ) {
          setIsSequenceReview(true);
        } else {
          return;
        }
        return;
      }

      if (childIndex === section.children.length - 1) {
        const sectionIndex = sections.findIndex(
          (sec: any) => sec.id === section.id
        );
        if (sectionIndex !== -1 && sectionIndex + 1 < sections.length) {
          const nextSectionUnitId =
            sections[sectionIndex + 1].children[0].children[0].id;
          setLastUnit(true);
          setNextUnitId(nextSectionUnitId);
          if (!unitData?.isCompleted) {
            navigateToUnit(nextSectionUnitId);
          } else if (!unitData?.isUnitReviewed && unitData?.isCompleted) {
            setIsUnitReview(true);
          } else if (
            unitData?.isUnitReviewed &&
            unitData?.isCompleted &&
            !unitData?.isSequenceReviewed
          ) {
            setIsSequenceReview(true);
          } else {
            return;
          }
        }
      }
    };

    const handlePrev = () => {
      if (targetIndex >= 0) {
        navigateToUnit(units[targetIndex].id);
        return;
      }

      const childIndex = section.children.findIndex(
        (c: any) => c.id === child.id
      );
      if (childIndex > 0) {
        const prevChildUnits = section.children[childIndex - 1].children;
        navigateToUnit(prevChildUnits[prevChildUnits.length - 1].id);
        return;
      }

      if (childIndex === 0) {
        const sectionIndex = sections.findIndex(
          (sec: any) => sec.id === section.id
        );
        if (sectionIndex > 0) {
          const prevSection = sections[sectionIndex - 1];
          const prevChildUnits =
            prevSection.children[prevSection.children.length - 1].children;
          navigateToUnit(prevChildUnits[prevChildUnits.length - 1].id);
        }
      }
    };

    direction === "next" ? handleNext() : handlePrev();
  };

  const isAccessible = (unitId: number) => {
    const unit = findUnitById(unitId);
    return unit ? unit.isAccessible : false;
  };

  const findUnitById = (unitId: number) => {
    for (const section of sections) {
      for (const child of section.children) {
        for (const unit of child.children) {
          if (unit.id === unitId) {
            return unit;
          }
        }
      }
    }
    return null;
  };

  const handleMenuClick = useCallback(
    (item: any) => {
      setUnitId(item?.id as number);
      if (item?.block && item?.block?.length && blockId !== item?.block[0]?.id)
        navigate(
          `${location.pathname}?blockId=${item?.block[0]?.id}&unitId=${item?.id}`
        );
    },
    [setUnitId, blockId]
  );

  const handleMarkComplete = async (e: CheckboxChangeEvent) => {
    const updatedSections = sections.map((section: any) => {
      const updatedChildren = section.children.map((child: any) => {
        const updatedUnits = child.children.map((unit: any) => {
          if (unit.id === unitId) {
            return { ...unit, isCompleted: e.target.checked };
          }
          return unit;
        });
        return { ...child, children: updatedUnits };
      });
      return { ...section, children: updatedChildren };
    });

    const payload = {
      unitId: unitId,
      isCompleted: e.target.checked,
    };

    try {
      const res = await checkUnitComplete(payload);
      notifySuccess(res?.data.message);
      setSections(updatedSections);
      setUnitData({
        ...unitData,
        isCompleted: e.target.checked,
      });
    } catch (error) {
      console.error("Failed to mark complete:", error);
    }
  };

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    setTempChecked(e.target.checked);
    setIsConfirmOpen(true);
  };

  const handleConfirmOk = async () => {
    const event = {
      target: {
        checked: tempChecked,
      },
      stopPropagation: () => {},
      preventDefault: () => {},
      nativeEvent: {},
    } as CheckboxChangeEvent;

    await handleMarkComplete(event);
    setIsConfirmOpen(false);
  };

  const onChangeSelect = async (value: string) => {
    setSearchType(value);
    const payload = {
      courseId: Number(id),
      type: value,
      keyword: keyword,
    };
    try {
      const res = await searchCourseStructure(payload);
      setCourseStructList(res?.data.data);
    } catch (error) {}
  };

  const handleSearch = async (value: string) => {
    setKeyword(value);
    const payload = {
      courseId: Number(id),
      type: searchType,
      keyword: value,
    };
    try {
      const res = await searchCourseStructure(payload);
      setCourseStructList(res?.data.data);
    } catch (error) {}
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      const inputValue = event.currentTarget.value;
      setKeyword(inputValue);
    }
  };
  // const handleSearchClick = () => {
  //   setKeyword(keyword);
  // };

  const handleSelect = (value: string, option: any) => {
    setSectionId(option.sectionId);
    setSequenceId(option.sequenceId);
    setTimeout(() => {
      scrollToElement(
        unitId ? `unit-${option.unitId}` : `sequence-${option.sequenceId}`
      );
    }, 1000);
  };

  const handleUnitReview = async () => {
    if (lastUnit && !unitData?.isSequenceReviewed) {
      try {
        const payload = {
          unitId: unitId,
          courseId: Number(id),
          star: starRating,
        };
        setIsLoadReview(true);
        reviewUnit(payload);
        setIsUnitReview(false);
        setTimeout(() => {
          setIsSequenceReview(true);
        }, 800);
      } catch (error) {
      } finally {
        setIsLoadReview(false);
      }
    } else {
      try {
        const payload = {
          unitId: unitId,
          courseId: Number(id),
          star: starRating,
        };
        setIsLoadReview(true);
        reviewUnit(payload);
        setIsUnitReview(false);
        navigateToUnit(nextUnitId);
      } catch (error) {
      } finally {
        setIsLoadReview(false);
      }
    }
  };

  const handleCancelUnitReview = () => {
    setIsUnitReview(false);
    if (lastUnit && !unitData?.isSequenceReviewed) {
      setTimeout(() => {
        setIsSequenceReview(true);
      }, 800);
    } else {
      navigateToUnit(nextUnitId);
    }
  };

  const handleSequenceReview = async () => {
    try {
      const payload = {
        sequenceId: unitData?.courseSequenceId,
        courseId: Number(id),
        star: starRating,
      };
      setIsLoadReview(true);
      reviewSequence(payload);
      setIsSequenceReview(false);
      navigateToUnit(nextUnitId);
    } catch (error) {
    } finally {
      setIsLoadReview(false);
    }
  };

  const handleCancelSequenceReview = async () => {
    setIsSequenceReview(false);
    navigateToUnit(nextUnitId);
  };

  const handleShareUnit = () => {
    setReloadTotal(!reloadTotal);

    countLearningCourse({
      unitId: unitId,
      sequenceId: unitData?.courseSequenceId,
      sectionId: unitData?.courseSectionId,
      courseId: Number(id),
      action: UNIT_SHARE,
      module: unitData?.module,
    });
    const currentURL = window.location.href;
    setReloadTotal(!reloadTotal);

    try {
      navigator.clipboard.writeText(currentURL);
      notifySuccess("Đã sao chép đường dẫn");
    } catch (error) {}
  };

  const handleDownloadUnit = async () => {
    setDownloading(true);
    try {
      if (unitData?.module === "quiz") {
        await downloadQuiz(Number(unitId))
          .then((res: any) => {
            const link = document.createElement("a");
            link.href = res.data;
            link.download = "";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .catch((error: any) => {
            notifyError((error as any)?.response?.data?.message);
          });
      } else if (unitData?.module === "text") {
        downloadWordDocument(
          unitData?.documents && unitData?.documents[0].text,
          unitData?.name
        );
      } else if (unitData?.module === "scorm" || unitData?.module === "xapi") {
        const documentUrl = unitData.scorms && unitData.scorms[0].mainKeyUrl;
        window.open(documentUrl, "_blank");
      } else {
        const response = await fetch(
          unitData?.documents && unitData?.documents[0].mainKeyUrl
        );
        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = `${unitData?.name}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        URL.revokeObjectURL(blobUrl);
      }

      countLearningCourse({
        unitId: unitId,
        sequenceId: unitData?.courseSequenceId,
        sectionId: unitData?.courseSectionId,
        courseId: Number(id),
        action: UNIT_DOWNLOAD,
        module: unitData?.module,
      });

      setReloadTotal(!reloadTotal);
    } catch (error) {
      console.error(error);
    } finally {
      setDownloading(false);
    }
  };

  const debouncedHandleDownload = useCallback(
    debounce(handleDownloadUnit, 300),
    [unitId, unitData]
  );
  const debouncedHandleShare = useCallback(debounce(handleShareUnit, 300), [
    unitId,
    unitData,
  ]);

  useEffect(() => {
    const units = sections.flatMap((section: any) =>
      (section.children ?? []).flatMap((child: any) =>
        (child.children ?? []).map((unit: any) => unit.id)
      )
    );
    setUnitList(units);
  }, [sections]);

  useEffect(() => {
    if (unitId && unitId !== 0) {
      setIsLoading(true);
      const fetchUnitDetail = async () => {
        try {
          const unitDetail = await getUnitDetail(unitId);
          if (unitDetail) {
            setUnitData(unitDetail?.data.data as any);
          }
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
      };
      fetchUnitDetail();
    }
  }, [unitId, reload]);

  useEffect(() => {
    if (unitId && unitId !== 0) {
      const fetchTotal = async () => {
        try {
          const unitDetail = await getUnitDetail(unitId);
          if (unitDetail) {
            setTotalAction({
              totalView: unitDetail?.data.data?.totalView,
              totalShare: unitDetail?.data.data?.totalShare,
              totalDownload: unitDetail?.data.data?.totalDownload,
            });
          }
        } catch (error) {}
      };
      fetchTotal();
    }
  }, [reloadTotal, unitId]);

  useEffect(() => {
    if (sections.length > 0 && unitId !== null) {
      const findParentKeys = (
        sections: Sections[],
        unitId: number
      ): string[] => {
        for (const section of sections) {
          if (section.children) {
            for (const child of section.children) {
              if (child.children) {
                for (const unit of child.children) {
                  if (unit.id === unitId) {
                    return [
                      `parent-${section.id}`,
                      `child-${child.id}`,
                      `parent-${section.id}-child-${child.id}-young-${unit.id}`,
                    ];
                  }
                }
              }
            }
          }
        }
        return [];
      };

      const keys = findParentKeys(sections, unitId);
      const newSelectedKeys = keys.slice(-1);
      if (JSON.stringify(newSelectedKeys) !== JSON.stringify(selectedKeys)) {
        setSelectedKeys(newSelectedKeys);
      }
      if (!keys.every((key) => openKeys.includes(key))) {
        setOpenKeys(keys);
      }
    }
  }, [unitId, sections]);

  useEffect(() => {
    if (sections.length > 0) {
      const findParentKeys = (
        sections: Sections[],
        sectionId: number,
        sequenceId: number
      ): string[] => {
        for (const section of sections) {
          if (section.id === sectionId) {
            if (section.children) {
              for (const child of section.children) {
                if (child.id === sequenceId) {
                  return [`parent-${section.id}`, `child-${child.id}`];
                }
              }
            }
          }
        }
        return [];
      };

      const keys = findParentKeys(
        sections,
        sectionId as any,
        sequenceId as any
      );
      if (!keys.every((key) => openKeys.includes(key))) {
        setOpenKeys(keys);
      }
    }
  }, [sections, sectionId, sequenceId, openKeys]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    const startCountdown = () => {
      setTimeLeft(Number(unitData?.timeCompletedCondition));
      intervalId = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(intervalId!);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden" && intervalId) {
        clearInterval(intervalId);
        intervalId = null;
      } else if (document.visibilityState === "visible" && !intervalId) {
        startCountdown();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    if (
      unitId &&
      document.visibilityState === "visible" &&
      tab === "1" &&
      unitData?.module !== "quiz" &&
      !unitData?.isCompleted
    ) {
      startCountdown();
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [unitId, tab, unitData?.module]);

  useEffect(() => {
    if (toNumber(useKeyword("unitId"))) {
      setUnitId(toNumber(useKeyword("unitId")));
    }
  }, [useKeyword("unitId")]);

  useEffect(() => {
    if (!unitId) {
      setSelectedKeys([]);
      setOpenKeys([]);
    }
  }, [tab, unitId]);
  return (
    <>
      {unitId ? null : (
        <>
          <div className="course-struct-search">
            <Select
              placeholder="Chọn thông tin cần tìm kiếm"
              onChange={onChangeSelect}
              filterOption={filterOption}
              defaultValue={"sequence"}
              options={optionFilterList}
              style={{
                width: "270px",
                height: "44px",
              }}
            />
            <AutoComplete
              options={courseStructList?.map((item) => ({
                value: item.name,
                ...item,
              }))}
              onSearch={handleSearch}
              onSelect={handleSelect}
              value={keyword}
              style={{
                width: "480px",
                height: "44px",
              }}
              notFoundContent={
                keyword ? <span>Không có kết quả</span> : undefined
              }
            >
              <Input
                placeholder={`Tìm kiếm ${searchType === "sequence" ? "bài giảng" : "học liệu"}`}
                prefix={
                  <img
                    src="/assets/icons/search-lg.svg"
                    alt="search"
                    className="search-icon"
                  />
                }
                style={{
                  width: "480px",
                  height: "44px",
                }}
                allowClear
                onKeyDown={handleKeyPress}
              />
            </AutoComplete>
          </div>
          <div className="hr-tag"></div>
        </>
      )}

      <div className="learning-course mb-5">
        {unitId ? (
          <div className="w-full mb-1 flex gap-24">
            <Button
              className={`btn-action-menu ${isToggle ? "btn-toggle-menu" : "btn-hide"}`}
              icon={
                <UnorderedListOutlined className="font-weight-6 font-size-16" />
              }
              onClick={() => setIsToggle(!isToggle)}
            >
              {isToggle ? " Ẩn cửa sổ menu" : ""}
            </Button>
            {!isLoading ? (
              <div className="learning-quiz-title font-size-24 line-height-32 mb-2 flex justify-between align-center w-full">
                {unitData?.name}

                {unitData?.module !== "quiz" ? (
                  <Checkbox
                    onChange={handleCheckboxChange}
                    checked={unitData?.isCompleted}
                    disabled={timeLeft !== 0 && unitData?.isCompleted !== true}
                  >
                    <div className="marker-text">Đánh dấu hoàn thành</div>
                  </Checkbox>
                ) : null}
              </div>
            ) : null}
          </div>
        ) : null}

        <div className="structure-content flex">
          <div
            className={
              unitId
                ? `structure-sidebar ${isToggle ? "sidebar-open" : "sidebar-hidden"}`
                : "w-full"
            }
          >
            <MoocMenu
              mode={unitId ? "small" : "default"}
              data={sections}
              selectedKeys={selectedKeys}
              openKeys={openKeys}
              onClick={(item) => confirmRedirect(() => handleMenuClick(item))}
              onOpenChange={(keys) => setOpenKeys(keys)}
            />
          </div>
          {unitId ? (
            <div className="learning-course-preview">
              {isLoading ? (
                <div className="learning-course-loading">
                  <Spin size="large" />
                </div>
              ) : (
                <>{renderMaterial(unitData as any)}</>
              )}

              <>
                <div className="unit-action">
                  <div className="flex gap-32">
                    <div className="flex gap-8">
                      <img
                        src="/assets/icons/eye-icon.svg"
                        alt="search"
                        className="popover-item-icon"
                      />
                      <div className="total-item">{totalAction.totalView}</div>
                    </div>
                    <div className="flex gap-8">
                      <img
                        src="/assets/icons/share-07.svg"
                        alt="search"
                        className="popover-item-icon"
                      />

                      <div className="total-item">{totalAction.totalShare}</div>
                    </div>
                    <div className="flex gap-8">
                      <img
                        src="/assets/icons/download.svg"
                        alt="search"
                        className="popover-item-icon"
                      />
                      <div className="total-item">
                        {totalAction.totalDownload}
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-16">
                    <Button
                      onClick={debouncedHandleDownload}
                      loading={downloading}
                    >
                      <img
                        src="/assets/icons/download.svg"
                        alt="search"
                        className="popover-item-icon"
                      />
                      Tải xuống
                    </Button>
                    <Button onClick={debouncedHandleShare}>
                      <img
                        src="/assets/icons/share-07.svg"
                        alt="search"
                        className="popover-item-icon"
                      />
                      chia sẻ
                    </Button>
                  </div>
                </div>
                <div className="hr-tag-item"></div>

                <div className="learning-paging">
                  <Button
                    onClick={() =>
                      confirmRedirect(() => handlePrevNext("prev"))
                    }
                    disabled={unitList.indexOf(unitId) <= 0}
                  >
                    <ArrowLeftOutlined />
                  </Button>
                  <Button
                    onClick={() =>
                      confirmRedirect(() => handlePrevNext("next"))
                    }
                    disabled={unitList.indexOf(unitId) >= unitList.length - 1}
                  >
                    <ArrowRightOutlined />
                  </Button>
                </div>

                {unitData?.module !== "quiz" && (
                  <div className="course-discussion">
                    <Tabs
                      defaultActiveKey="1"
                      onTabClick={(key, event) => {
                        setUnitTypeTab(key);
                        const target = event.target as HTMLElement;
                        if (target.closest(".ant-tabs-extra-content")) {
                          toggleContentVisibility();
                        }
                      }}
                      tabBarExtraContent={
                        <div
                          onClick={toggleContentVisibility}
                          className="btn-extra-content"
                        >
                          {isContentVisible ? <DownOutlined /> : <UpOutlined />}
                        </div>
                      }
                    >
                      <TabPane
                        tab={
                          <span>
                            Ghi chú
                            <span className="badge">
                              {unitData?.totalNote || 0}
                            </span>
                          </span>
                        }
                        key="1"
                      >
                        {isContentVisible && (
                          <UnitNotes unitId={unitId} unitData={unitData} />
                        )}
                      </TabPane>
                      <TabPane
                        tab={
                          <span>
                            Thảo luận
                            <span className="badge">
                              {unitData?.totalDiscussion || 0}
                            </span>
                          </span>
                        }
                        key="2"
                      >
                        {isContentVisible && (
                          <>
                            <Discussion unitId={unitId} tab={unitTypeTab} />
                          </>
                        )}
                      </TabPane>
                    </Tabs>
                  </div>
                )}
              </>
            </div>
          ) : null}
        </div>
        {isCloseCamera === "open" &&
          search.includes(`blockId=${attendanceData.course_block_id}`) && (
            <FaceRecordModal
              unitType="video"
              // currentVideoTimestamp={Number((played * duration).toFixed(2))}
              type="study"
              description="Vui lòng giữ mặt trong khung hình trong quá trình học"
              title="Điểm danh"
              isOpen={isExpand}
              setCloseCamera={setIsCloseCamera}
              isCloseCamera={isCloseCamera}
              handleClose={() => setIsExpand(false)}
              attendanceData={attendanceData}
            />
          )}
        <ConfirmModal
          open={isConfirmOpen}
          handleOk={handleConfirmOk}
          handleCancel={() => setIsConfirmOpen(false)}
          title={`Xác nhận ${unitData?.isCompleted ? "bỏ tích" : "tích"} hoàn thành nội dung`}
          confirmIcon={`${process.env.PUBLIC_URL}/assets/icons/check-circe-primary.svg`}
          content={`Bạn có chắc chắn muốn ${unitData?.isCompleted ? "bỏ tích" : "tích"} chọn hoàn thành nội dung này?`}
        />
        <LearningReviewModal
          open={isUnitReview || isSequenceReview}
          handleOk={isUnitReview ? handleUnitReview : handleSequenceReview}
          handleCancel={() =>
            isUnitReview
              ? handleCancelUnitReview()
              : handleCancelSequenceReview()
          }
          title={`Đánh giá về ${isUnitReview ? "học liệu" : "bài giảng"}`}
          content={`Hãy cho chúng tôi biết đánh giá của bạn nhé!`}
          setStarRating={setStarRating}
          loading={isLoadReview}
        />
      </div>
    </>
  );
};
export default LearningCourse;

const optionFilterList = [
  { value: "sequence", label: "Bài giảng" },
  { value: "unit", label: "Học liệu" },
];
