import React, { useEffect, useState } from "react";
import Banner from "../../components/banner/Banner";
import { Button, Input } from "antd";
import "./style.scss";
import SimpleTabs from "../../components/tabs/SimpleTabs";
import {
  COURSE_SCHEDULE_TYPE,
  COURSE_TYPE,
  DEFAULT_PAGE_NUM,
  DEFAULT_PAGE_SIZE,
  FILTER_STAR_ARR,
  FREE_OPTIONS,
  PAID_VALUE_FILTER,
} from "../../constants";
import ResponsiveList from "../../components/list/ResponsiveList";
import CourseCard, { CourseCardProps } from "../../components/card/CourseCard";
import Filter from "../../components/filter";
import { useNavigate } from "react-router-dom";
import { routesConfig } from "../../config/routes";
import {
  exportExcelCourse,
  getCoursesBlockQuiz,
  getCoursesToStarted,
  saveSearchHistory,
} from "../../service/courses";
import {
  convertSomeObjKeyToArray,
  mapCourseInfoBeToFe,
} from "../../utils/format";
import { CourseInfo, CoursePayload, IndustryInfo } from "../../types/course";
import CustomPagination from "../../components/pagination/Pagination";
import { isEmpty } from "lodash";
import { useFilters } from "./Filters";
import { globalStore, useCoursesStore } from "../../stores/stores";
import { getArrOrUndefined, getArrayFilterChecked } from "../../utils/arrays";
import useMetaData from "../../hooks/useMetaData";
import useKeyword from "../../hooks/useKeyword";

function CourseInformation() {
  const navigate = useNavigate();

  const { industries, universities } = useCoursesStore((state) => state);
  const { reload } = globalStore();
  const [keySearch, setKeySearch] = useState(useKeyword("keyword") || "");
  const [initialCourses, setInitialCourses] = useState<CourseCardProps[]>([]);
  const [courses, setCourses] = useState<CourseCardProps[]>([]);

  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const [pageNum, setPageNum] = useState<number>(DEFAULT_PAGE_NUM);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [searchValues, setSearchValues] = useState<CoursePayload>({
    sortByName: "asc",
  });
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [hideCourseToStarted, setHideCourseToStarted] =
    useState<boolean>(false);
  const [starterCourseList, setStarterCourseList] = useState<CourseCardProps[]>(
    []
  );

  const { allIndustry } = useMetaData();
  const topicId = Number(useKeyword("topic"));
  const currentIndustry = allIndustry?.find(
    (item: IndustryInfo) => item.id === topicId
  );
  const filterMenus = useFilters({
    hasPaid: searchValues?.isFreeOptions?.includes(PAID_VALUE_FILTER)
      ? true
      : false,
  });

  const checkDisplay = location.pathname.includes(
    routesConfig.universityCourseTemplateDetail
  );
  const dataPath = location.search?.split("&slug=");

  useEffect(() => {
    if (topicId) {
      setSearchValues((prevState: any) => ({
        ...prevState,
        industries: [topicId],
      }));
    }
  }, [topicId]);

  useEffect(() => {
    const initialCourse = async () => {
      // Lấy danh sách khoá học
      const coursesInfo = await getCourses();
      setInitialCourses(renderCard(coursesInfo));
    };
    initialCourse();
  }, [reload]);

  useEffect(() => {
    const fetchCoursesToStarted = async () => {
      const params = {
        pageIndex: 1,
        pageSize: 6,
      };
      const coursesStart = await getCoursesToStarted(params);
      const renderData = coursesStart?.data?.data?.map((item: CourseInfo) =>
        mapCourseInfoBeToFe(item)
      );

      setStarterCourseList(renderCard(renderData));
    };
    fetchCoursesToStarted();
  }, []);

  useEffect(() => {
    if (
      isEmpty(searchValues) &&
      pageNum === DEFAULT_PAGE_NUM &&
      pageSize === DEFAULT_PAGE_SIZE
    )
      setCourses(initialCourses);
    else {
      // Lọc danh sách khoá học
      searchCourse();
    }
  }, [initialCourses, searchValues, pageNum, pageSize, reload]);

  const renderCard = (courses: any) => {
    if (!courses.length) return [];

    return courses.map((item: CourseCardProps) => ({
      content: (
        <CourseCard
          key={item.id}
          {...item}
          onClick={() =>
            navigate(`${routesConfig.courseInfo}/detail/${item.id}`)
          }
          isPopular={false}
          teachers={item.teachers?.filter(
            (teacher: string, index) => teacher !== " "
          )}
        />
      ),
    }));
  };

  const handleClickSearch = () => {
    searchCourse();

    if (keySearch) {
      saveHistorySearch(keySearch);
      navigate(
        `${routesConfig.courseInfo}${keySearch ? `?keyword=${keySearch.trim()}` : ""}`
      );
      setHideCourseToStarted(true);
    } else {
      navigate(`${routesConfig.courseInfo}`);
      setHideCourseToStarted(false);
    }
  };

  const onKeyBoardEnter = (e: any) => {
    if (e.key === "Enter") {
      navigate(
        `${routesConfig.courseInfo}${e.target.value ? `?keyword=${e.target.value.trim()}` : ""}`
      );
      saveHistorySearch(e.target.value.trim());
      searchCourse();
      if (keySearch) {
        setHideCourseToStarted(true);
      } else {
        setHideCourseToStarted(false);
      }
    }
  };

  const getCourses = async (payload = {}) => {
    try {
      const res = await getCoursesBlockQuiz(payload);
      const coursesInfo = res.data.data.courses.map((item: CourseInfo) =>
        mapCourseInfoBeToFe(item)
      );
      setTotalRecords(res.data.data.total);
      return coursesInfo;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const searchCourse = async () => {
    try {
      const uuid = dataPath && dataPath[0].replace("?uuid=", "");

      setLoadingSearch(true);
      const values = await getCourses({
        ...searchValues,
        page: pageNum,
        size: pageSize,
        keyword: keySearch.trim(),
        universities: !location.pathname.includes(
          routesConfig.universityCourseTemplateDetail
        )
          ? searchValues?.universities
          : [uuid],
      });
      setCourses(renderCard(values));
    } catch (err) {
    } finally {
      setTimeout(() => setLoadingSearch(false), 500);
    }
  };

  const saveHistorySearch = async (keyWord: string) => {
    await saveSearchHistory({
      type: 1, // khoá học
      keyword: keyWord,
    });
  };

  const handleChangeFilter = (value: any) => {
    // Filter chuyên ngành
    const industriesFilter = getArrayFilterChecked(
      industries,
      convertSomeObjKeyToArray(value, "industry"),
      "id"
    );
    // Xếp hạng
    const stars = getArrayFilterChecked(
      FILTER_STAR_ARR,
      convertSomeObjKeyToArray(value, "rank"),
      "value"
    );
    // Chi phí
    const freeOptions = getArrayFilterChecked(
      FREE_OPTIONS,
      convertSomeObjKeyToArray(value, "price"),
      "value"
    );
    // Filter trường học
    const universitiesFilter = getArrayFilterChecked(
      universities,
      convertSomeObjKeyToArray(value, "university"),
      "uuid"
    );
    console.log(universitiesFilter);

    // Filter lịch trình học
    const courseScheduleType = getArrayFilterChecked(
      COURSE_SCHEDULE_TYPE,
      convertSomeObjKeyToArray(value, "schedule"),
      "value"
    );
    // Filter loại khoá học
    const courseType = getArrayFilterChecked(
      COURSE_TYPE,
      convertSomeObjKeyToArray(value, "kindOfCourse"),
      "value"
    );
    const payload = {
      industries: getArrOrUndefined(industriesFilter),
      universities: getArrOrUndefined(universitiesFilter),
      stars: getArrOrUndefined(stars),
      courseScheduleType: getArrOrUndefined(courseScheduleType),
      courseType: getArrOrUndefined(courseType),
      isFreeOptions: getArrOrUndefined(freeOptions),
      minCost: freeOptions?.includes(PAID_VALUE_FILTER) ? 0 : undefined,
      maxCost:
        freeOptions?.includes(PAID_VALUE_FILTER) && value?.cost
          ? value?.cost
          : freeOptions?.includes(PAID_VALUE_FILTER) && !value?.cost
            ? 0
            : undefined,
    };
    setPageNum(1);

    setSearchValues((prevState: CoursePayload) => ({
      ...prevState,
      ...payload,
    }));
  };

  const handleChangeSort = (value: number) => {
    switch (value) {
      case 1:
        setSearchValues((prevState: CoursePayload) => ({
          ...prevState,
          ...{ sortByName: "asc", sortByCreatedAt: undefined },
        }));
        break;
      case 2:
        setSearchValues((prevState: CoursePayload) => ({
          ...prevState,
          ...{ sortByName: "desc", sortByCreatedAt: undefined },
        }));
        break;
      case 3:
        setSearchValues((prevState: CoursePayload) => ({
          ...prevState,
          ...{ sortByCreatedAt: "desc", sortByName: undefined },
        }));
        break;
      case 4:
        setSearchValues((prevState: CoursePayload) => ({
          ...prevState,
          ...{ sortByCreatedAt: "asc", sortByName: undefined },
        }));
        break;
      default:
        break;
    }
  };

  const handleExportExcel = async () => {
    try {
      const res = await exportExcelCourse({
        ...searchValues,
        page: pageNum,
        size: pageSize,
        industryGroup: topicId || undefined,
      });
      const fileData = res.data;

      const blob = new Blob([fileData], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);

      downloadLink.download = "Danh sách khóa học.xlsx";

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error("Error downloading Excel file:", error);
    }
  };

  const handleClickGroupIndustry = (group: any) => {
    navigate(`${routesConfig.courseInfo}?topic=${group.value}`);
  };

  const handleClickMore = () => {
    navigate(`${routesConfig.courseTopics}`);
  };

  return (
    <div>
      {!checkDisplay ? (
        <Banner>
          <section className="center flex-column course-info-banner-content">
            <span className="mb-2 text-violet">Danh sách khoá học</span>
            <h3 className="mb-3 text-white banner-title">
              {currentIndustry
                ? `Các khoá học về ${currentIndustry.name}`
                : "Tất cả khoá học"}
            </h3>
            <span className="mb-4 text-violet">
              Với 1000+ các khoá học với nhiều chủ đề khác nhau
            </span>

            <div className="search flex align-center">
              <Input
                className="search-input"
                value={keySearch}
                onKeyDown={onKeyBoardEnter}
                onChange={(e) => setKeySearch(e.target.value)}
                placeholder="Nhập từ khoá cần tìm"
                allowClear
              />
              <Button
                className="mooc-btn-violet btn-primary"
                onClick={handleClickSearch}
              >
                Tìm kiếm
              </Button>
            </div>
          </section>
        </Banner>
      ) : null}
      <section className="content-layout">
        {!topicId ? (
          <section className="tabs-ss">
            <SimpleTabs
              tabs={allIndustry?.map((item: IndustryInfo) => ({
                label: item.name,
                value: item.id,
              }))}
              show={10}
              onClick={handleClickGroupIndustry}
              onClickMore={handleClickMore}
            />
          </section>
        ) : (
          ""
        )}
        {!topicId && !hideCourseToStarted ? (
          <section className="list-ss">
            <ResponsiveList
              title="Khoá học để bạn bắt đầu"
              data={starterCourseList}
            />
          </section>
        ) : (
          ""
        )}

        <section className="filter-content mt-2">
          <Filter
            items={filterMenus}
            onChange={handleChangeFilter}
            onChangeSort={handleChangeSort}
          />
          <div className="content-list w-full-100">
            <ResponsiveList
              loading={loadingSearch}
              className="mt-5"
              showMenu={true}
              totalRecords={totalRecords}
              grid={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3, xxl: 3 }}
              data={courses}
              onClickExportExcel={handleExportExcel}
              onClickReport={() => navigate(routesConfig.statisticsCourses)}
            />
            {totalRecords > 0 && (
              <CustomPagination
                totalPage={totalRecords}
                pageSize={pageSize}
                pageNumber={pageNum}
                setPageSize={(value) => setPageSize(value)}
                setPageNumber={(value) => setPageNum(value)}
              />
            )}
          </div>
        </section>
      </section>
    </div>
  );
}

export default CourseInformation;
