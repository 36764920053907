import { axiosConfig } from "../../config/api/configApi";
import { AxiosResponse } from "axios";
import { axiosConfigToUpload } from "../../config/api/configApiUpload";
import { axiosConfigV3 } from "../../config/api/configApiV3";

export const getLanguages: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/lookup/get-language`);
};

export const getTimeZone: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/lookup/get-time-zone`);
};

export const updateInfo: (
  dataUpdate: any | { image: string }
) => Promise<AxiosResponse<any>> = (dataUpdate) => {
  return axiosConfig.put(`/pesonals/update-personal`, dataUpdate);
};

export const deleteInfo: (dataDelete: any) => Promise<AxiosResponse<any>> = (
  dataDelete
) => {
  return axiosConfig.delete(`/pesonals/delete`, dataDelete);
};

export const getInfoPersonal: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/v2/api/account/get-student-profile`);
};

export const changePasswordPersonal: (
  dataChange: any
) => Promise<AxiosResponse<any>> = (dataChange) => {
  return axiosConfig.put(`/pesonals/change-password-personal`, dataChange);
};

export const getIndustryList: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/lookup/get-industry-group`);
};

export const editAccountInfo: (payload: any) => Promise<AxiosResponse<any>> = (
  payload
) => {
  return axiosConfigToUpload.put(`/v2/api/account/update-profile`, payload);
};

export const getUniversity: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/lookup/get-schools`);
};

export const getIndustry: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.post(`/v2/open-api/course/get-all-industry`, {});
};

export const getSkills: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/v2/open-api/profile/get-student-academic-level`);
};

export const getRoles: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/v2/open-api/profile/get-positions`);
};

export const getTypesSchool: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/v2/open-api/mooc-portfolio/get-training-type-by-name`);
};

export const getTypes: (name: string) => Promise<AxiosResponse<any>> = (name) => {
  return axiosConfig.get(`/v2/open-api/mooc-portfolio/get-university-type-by-name?name=${name}`);
};

export const getManagementAgencys: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/v2/open-api/mooc-portfolio/get-organization-by-name`);
};

export const getCountry: (code?: string) => Promise<AxiosResponse<any>> = (
  code
) => {
  return axiosConfig.get(`/v2/open-api/address/countries`);
};

export const getProvinces: (code?: string) => Promise<AxiosResponse<any>> = (
  code
) => {
  return axiosConfig.get(`/v2/open-api/address/provinces?code=${code}`);
};

export const getDistricts: (code?: string) => Promise<AxiosResponse<any>> = (
  code
) => {
  return axiosConfig.get(`/v2/open-api/address/districts?code=${code}`);
};

export const getWards: (code?: string) => Promise<AxiosResponse<any>> = (
  code
) => {
  return axiosConfig.get(`/v2/open-api/address/wards?code=${code}`);
};

export const getUniversityForStudent: () => Promise<
  AxiosResponse<any>
> = () => {
  return axiosConfig.get(`/v2/open-api/university/find-by-name`);
};

export const createJoinMooc: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfig.post("/v2/open-api/mooc-university-register", data);
};

export const createRole: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfig.post("/roles", data);
};

export const requestDeleteAccount: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfig.post(
    "/v2/api/account/delete-account/request-del-account-of-student",
    payload
  );
};

export const deleteAccountByPassword: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfig.post(
    "/v2/api/account/delete-account/confirm-by-password",
    payload
  );
};

export const deleteAccountByOtp: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfig.post(
    "/v2/api/account/delete-account/confirm-by-otp",
    payload
  );
};

export const getDeleteAccOtp: (payload: any) => Promise<AxiosResponse<any>> = (
  payload
) => {
  return axiosConfig.post("/v2/api/account/delete-account/send-otp", payload);
};

export const getReasonDeleteAccount: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/v2/api/account/delete-account/reasons`);
};
